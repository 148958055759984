import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import styles from './back-button-styles.scss';

const BackButton = ({ className, cb, routes }) => {
  const goBackUrl = routes.length > 1 ? null : '/';
  return goBackUrl ? (
    <Link
      className={`${styles.backButton} ${className}`}
      onClick={cb}
      to={goBackUrl}
      href={goBackUrl}
    >
      {'<'}
    </Link>
  ) : (
    <button
      aria-label="back button"
      className={`${styles.backButton} ${className}`}
      onClick={() => window.history.back() || cb()}
    >
      {'<'}
    </button>
  );
};
BackButton.defaultProps = {
  cb: () => null,
  className: '',
  routes: [],
};

const mapStateToProps = ({ router }) => ({
  routes: router.routes,
});

BackButton.propTypes = {
  cb: PropTypes.func,
  className: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.string),
};

export default connect(mapStateToProps)(BackButton);
