import React from 'react';
import { Link } from 'react-router';

import ExternalLinkIcon from '../../icons/ExternalLinkIcon';

import styles from './primary-link-button-styles.scss';

const PrimaryLinkButton = ({
  children,
  className = '',
  href = '',
  external = true,
  cb = () => null,
}) =>
  external ? (
    <a
      className={`${styles.primaryButton} ${className}`}
      href={href}
      target="_blank"
      onClick={cb}
    >
      <span>{children}</span>
      {<ExternalLinkIcon className={styles.icon} />}
    </a>
  ) : (
    <Link
      onClick={cb}
      className={`${styles.primaryButton} ${className}`}
      to={href}
    >
      {children}
    </Link>
  );

export default PrimaryLinkButton;
