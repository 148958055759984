import moment from 'moment';

import { REQUEST_GIGS, RECIEVE_GIGS } from './action-creators';

const initialState = [];

const gigsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GIGS:
      return state;
    case RECIEVE_GIGS:
      // TODO move to gigs view functionality;
      const gigsbyDay = [];
      action.gigsData.forEach((gig) => {
        const index = gigsbyDay.findIndex(
          (gigByDate) => gigByDate.date === moment(gig.date).format('YYYYMMDD')
        );
        if (index === -1) {
          const obj = {
            date: moment(gig.date).format('YYYYMMDD'),
            gigs: [Object.assign({}, gig)],
          };
          gigsbyDay.push(obj);
        } else {
          gigsbyDay[index].gigs.push(Object.assign({}, gig));
        }
      });
      return gigsbyDay;
    default:
      return state;
  }
};

export default gigsReducer;
