import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './player-styles.scss';

class TrackInfo extends PureComponent {
  render() {
    return (
      <div className={styles.trackInfo}>
        <a
          className={styles.trackInfo}
          href={`https://soundcloud.com/${this.props.permalink}`}
          target="_blank"
        >
          <h3>{this.props.username}</h3>
        </a>
        <a
          className={styles.trackInfo}
          href={this.props.trackLink}
          target="_blank"
        >
          <h4>{this.props.title}</h4>
        </a>
      </div>
    );
  }
}

TrackInfo.propTypes = {
  permalink: PropTypes.string,
  username: PropTypes.string,
  trackLink: PropTypes.string,
  title: PropTypes.string,
};

export default TrackInfo;
