import Helmet from 'react-helmet'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Header from './components/header/header'
import Player from './components/player'
import Footer from './components/footer/footer'
import styles from './app-styles.scss'

import SideBar from './components/menu/side-bar'

const App = ({ children }) => (
  <div className={styles.container}>
    <Helmet
      defaultTitle='Small gigs in London | StickyFloors'
      titleTemplate='%s | StickyFloors'
      og={{ title: 'test' }}
    >
      <meta property='og:title' content='Small Gigs Listing in London' />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://stickyfloors.net' />
      <meta name='theme-color' content='#000000' />
      <meta charSet='utf-8' />
      <meta
        property='og:image'
        content='https://stickyfloors.net/img/og-pic.jpg'
      />
      <meta property='og:site_name' content='Sticky Floors' />
      <meta property='og:locale' content='en_US' />
      <meta
        property='og:description'
        content='Listing of cheap gigs in London usually bellow £10'
      />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='_stickyfloors_' />
      <meta name='twitter:title' content='Small Gigs Listing in London' />
      <meta
        name='twitter:description'
        content='Small Gigs Listing in London, usually bellow £10'
      />
      <meta name='twitter:image:alt' content='Sticky Floors Logo' />
      <meta
        name='twitter:image'
        content='https://stickyfloors.net/img/twitter-card-pic.jpg'
      />
    </Helmet>
    <Header />

    <div className={styles.container}>{children}</div>
    <SideBar />
    <Footer />
    <Player />
  </div>
)

App.propTypes = {
  children: PropTypes.shape({}),
}
App.defaultProps = { children: '' }

export default connect()(App)
