import React from 'react';
import styles from './footer-styles.scss';
import SocialMediaBar from '../social-media-bar/social-media-bar';

const Footer = () => (
  <footer className={styles.footer}>
    <nav>
      <ul>
        <li>
          <a
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://soundcloud.com/stickyfloors/sets/playing-this-week"
          >
            SoundCloud weekly playlist
          </a>
        </li>
        <li>
          Contact{' '}
          <a
            className={styles.link}
            href="mailto:stickyfloorsgigs@gmail.com?Subject=hello%20there%21"
          >
            stickyfloorsgigs@gmail.com
          </a>
        </li>
        <li>
          <SocialMediaBar
            className={styles.socialMediaBar}
            twitterUrl="https://twitter.com/_stickyfloors_"
            facebookUrl="https://www.facebook.com/StickyFloors-1170717093093696"
            soundcloudUrl="https://soundcloud.com/stickyfloors"
          />
        </li>
      </ul>
    </nav>
  </footer>
);

export default Footer;
