import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { connect } from 'react-redux'
import { getVenues } from './action-creators'
import VenueList from '../../components/venues-list/venues-list'
import StickyFloorsText from '../../components/sticky_floors_text/sticky_floors_text'
import styles from '../views-styles.scss'

class VenuesView extends Component {
  componentDidMount () {
    if (this.props.venues.length === 0) {
      this.props.getVenues()
    }
    if (typeof window !== 'undefined' && window.ga && this.props.isFirstPage) {
      window.ga('send', 'event', 'Start|VenuesPage', 'load', 'Venues page')
    }
  }

  render () {
    return (
      <div className={styles.tranistionIn}>
        <Helmet title='List of venues'>
          <link rel='canonical' href='https://stickyfloors.net/venues' />
        </Helmet>
        <h1>
          <StickyFloorsText
            className={styles.randomLine}
            textData={this.props.venuesText}
          />
        </h1>

        <VenueList />
      </div>
    )
  }
}

VenuesView.contextTypes = {
  router: PropTypes.object,
}

VenuesView.defaultProps = {
  getVenues: () => null,
}

VenuesView.need = [() => getVenues()]

function mapStateToProps (store) {
  return {
    venues: store.venuesData,
    venuesText: store.randomText.venues[0],
    isFirstPage: store.router.routes.length === 0,
  }
}

VenuesView.propTypes = {
  venues: PropTypes.array,
  venuesText: PropTypes.array,
  getVenues: PropTypes.func,
  isFirstPage: PropTypes.bool,
}

export default connect(mapStateToProps, { getVenues })(VenuesView)
