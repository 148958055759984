import React from 'react';

const CloseIcon = () => (
  <svg viewBox="0 0 330 330">
    <path
      fill="white"
      d="M84.394,245.606C87.323,248.536,91.161,250,95,250c3.838,0,7.678-1.465,10.606-4.394l59.393-59.392l59.391,59.392
            c2.93,2.93,6.768,4.394,10.607,4.394c3.838,0,7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213l-59.391-59.393
            l59.394-59.393c5.858-5.857,5.858-15.355,0-21.213c-5.857-5.858-15.354-5.858-21.213,0L165,143.788l-59.393-59.395
            c-5.859-5.858-15.355-5.858-21.213,0c-5.858,5.857-5.858,15.355,0,21.213l59.393,59.395l-59.393,59.393
            C78.536,230.251,78.536,239.749,84.394,245.606z"
    />
  </svg>
);

export default CloseIcon;
