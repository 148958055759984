import {
  REQUEST_VENUE,
  RECIEVE_VENUE,
  RESET_VENUE_DATA,
} from './action-creators';

const initialState = {
  venueDetails: { isLoading: true, venueGigs: [] },
};

const venueDetailsReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case RESET_VENUE_DATA:
      return Object.assign({}, initialState);
    case REQUEST_VENUE:
      newState = Object.assign({}, state);
      newState.isLoading = true;
      return newState;
    case RECIEVE_VENUE: {
      newState = Object.assign({}, state);
      newState.venueDetails = action.venueDetails;
      newState.isLoading = false;
      return newState;
    }
    default:
      return state;
  }
};

export default venueDetailsReducer;
