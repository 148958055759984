import {
  REQUEST_GIG,
  RECIEVE_GIG,
  RECIEVE_SIMILAR_GIGS,
  REQUEST_SIMILAR_GIGS,
  RESET_GIGS,
} from './action_creators';

const initialState = {
  gigDetails: { venueData: {} },
  similarGigs: [],
  isLoading: true,
  isSimilarGigsLoading: true,
  timeStamp: '',
};
const gigDetailsReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case RESET_GIGS:
      return Object.assign({}, initialState);
    case REQUEST_GIG:
      newState = Object.assign({}, state);
      newState.isLoading = true;
      return newState;
    case RECIEVE_GIG: {
      newState = Object.assign({}, state);
      newState.gigDetails = action.gigDetails;
      newState.timeStamp = Date.now();
      newState.isLoading = false;
      return newState;
    }
    case REQUEST_SIMILAR_GIGS:
      newState = Object.assign({}, state);
      newState.isSimilarGigsLoading = true;
      return newState;
    case RECIEVE_SIMILAR_GIGS: {
      newState = Object.assign({}, state);
      newState.similarGigs = action.similarGigs;
      newState.isSimilarGigsLoading = false;
      return newState;
    }
    default:
      return state;
  }
};
export default gigDetailsReducer;
