import React from 'react';

const MenuIcon = () => (
  <svg
    height="40px"
    viewBox="0 0 40 40"
    width="40px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect fill="black" height="40" width="40" />

    <g>
      <rect fill="white" height="4" width="40" x="0" y="8" />
      <rect fill="white" height="4" width="40" x="0" y="18" />
      <rect fill="white" height="4" width="40" x="0" y="28" />
    </g>
  </svg>
);

export default MenuIcon;
