import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// import GigInfoCard from '../../../components/gig_info_card/GigInfoCard';
//
// import BandInfoCard from '../../../components/BandInfoCard/BandInfoCard';
import Accordion from '../../../components/accordion/accordion';
import BackButton from '../../../components/back-button/back-button';
import HtmlText from '../../../components/html-text/html-text';
import HeroImage from '../../../components/UIComponents/hero-image/hero-image';
import LocationButton from '../../../components/UIComponents/location-button/location-button';
import PrimaryLinkButton from '../../../components/UIComponents/primary-button/primary-link-button';
import TooLateNotice from '../../../components/too-late-notice/too-late-notice';
import GigListCompact from '../../../components/gig-list-compact/gig-list-compact';

import { getPriceString } from '../../../utils/parsing_utils';

import styles from './future-gigs-view-styles.scss';
import viewStyles from '../../views-styles.scss';
import ShareButton from '../../../components/UIComponents/share-button/share-button';

const TicketLink = ({
  price,
  ticketsLink,
  attribution,
  className,
  cb = () => null,
}) => {
  let link = null;
  const priceString = getPriceString(price);
  if (ticketsLink && ticketsLink.indexOf('http') > -1) {
    link = (
      <PrimaryLinkButton
        className={className}
        href={ticketsLink}
        target="_blank"
        cb={cb}
      >
        Tickets {priceString}
      </PrimaryLinkButton>
    );
  } else if (attribution && attribution[0]) {
    link = (
      <PrimaryLinkButton
        className={className}
        href={attribution[0].url}
        target="_blank"
        cb={cb}
      >
        {priceString === '' ? 'MORE DETAILS' : `PRICE: ${priceString}`}
      </PrimaryLinkButton>
    );
  }
  return link;
};

class FutureGigView extends Component {
  static onBackClick() {
    // TODO middleware analitycs
    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'event', 'BackButton', 'click', 'Gig details page');
    }
  }

  static ticketLinkClick() {
    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'event', 'TicketLink', 'click', 'Gig details page');
    }
  }

  static onLocationClick() {
    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'event', 'LocationLink', 'click', 'Gig details page');
    }
  }

  static onMoreatVenueClick() {
    if (typeof window !== 'undefined' && window.ga) {
      window.ga(
        'send',
        'event',
        'MoreGigsAtVenue',
        'click',
        'Venue details page'
      );
    }
  }

  static onSimilarClick() {
    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'event', 'SimilarLink', 'click', 'Gig details page');
    }
  }

  static getimg(id) {
    return id
      ? `https://res.cloudinary.com/stickyfloors/image/upload/c_fill,e_grayscale,g_faces,h_600,q_auto:best,w_600/v1541513319/${id}.jpg`
      : null;
  }
  componentDidMount() {
    const isFuture = moment().diff(this.props.date, 'hours') <= 8;
    if (typeof window !== 'undefined' && window.ga && this.props.isFirstPage) {
      window.ga(
        'send',
        'event',
        isFuture ? 'Start|FutureGigLandingPage' : 'Start|PastGigLandingPage',
        'load',
        'Gig details page'
      );
    }
  }

  render() {
    const date = moment().isSame(this.props.date, 'year')
      ? `${moment(this.props.date).format('dddd Do MMMM')}`
      : `${moment(this.props.date).format('dddd Do MMMM YYYY')}`;
    const isFuture = moment().diff(this.props.date, 'hours') <= 8;

    return (
      <div>
        {!isFuture && <TooLateNotice date={this.props.date} />}
        <BackButton
          className={viewStyles.backButton}
          cb={FutureGigView.onBackClick}
        />
        <HeroImage
          alt={this.props.img.alt || this.props.venueName}
          src={
            FutureGigView.getimg(this.props.img.cloudinaryId) ||
            this.props.venueImg
          }
        />
        <div className={styles.contentContainer}>
          <h3 className={styles.date}>
            <time dateTime={this.props.date}>{date}</time>
          </h3>
          <div className={styles.titleSection}>
            <div>
              <h1 className={styles.title}>{this.props.title}</h1>

              <h3 className={styles.doors}>
                Doors:{' '}
                <time dateTime={this.props.date}>
                  {moment(this.props.date).format('H:mm')}
                </time>
              </h3>
            </div>{' '}
            <ShareButton
              className={styles.shareButton}
              url={`https://stickyfloors.net/gig/${this.props.slug}`}
              title={this.props.title}
            />
          </div>
          <LocationButton
            loc={this.props.loc}
            className={styles.locationLink}
            address={`${this.props.venueAddres} ${this.props.venuePostcode} ${
              this.props.venueCity
            }`}
            name={this.props.venueName}
            cb={FutureGigView.onLocationClick}
          />

          <nav className={styles.secondaryNav}>
            {!isFuture && (
              <PrimaryLinkButton
                external={false}
                className={styles.venueLink}
                onClick={FutureGigView.onMoreatVenueClick}
                to={`/venue/${this.props.venueSlug}`}
                href={`/venue/${this.props.venueSlug}`}
              >
                More Gigs at{' '}
                <span className={styles.venueName}>{this.props.venueName}</span>
              </PrimaryLinkButton>
            )}
            {isFuture && (
              <TicketLink
                className={styles.ticketButton}
                price={this.props.price}
                ticketsLink={this.props.ticketsLink}
                attribution={this.props.attribution}
                cb={FutureGigView.ticketLinkClick}
              />
            )}
          </nav>

          {this.props.description ? (
            <section className={styles.section}>
              <Accordion
                open
                header={
                  <h3 className={viewStyles.accHeader}>Gig Description</h3>
                }
              >
                <HtmlText
                  className={styles.description}
                  htmlText={this.props.description}
                  attribution={this.props.attribution}
                />
              </Accordion>
            </section>
          ) : null}
          {this.props.similarGigs.length > 0 ? (
            <section className={styles.section}>
              <Accordion
                open
                header={<h3 className={viewStyles.accHeader}>Similar Gigs</h3>}
              >
                <GigListCompact
                  cb={FutureGigView.onSimilarClick}
                  className={styles.similar}
                  gigs={this.props.similarGigs}
                />
              </Accordion>
            </section>
          ) : null}
        </div>
      </div>
    );
  }
}

FutureGigView.defaultProps = {
  bands: [],
  similarGigs: [],
};

FutureGigView.propTypes = {
  gigDetailsText: PropTypes.array,
  img: PropTypes.object,
  venueImg: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  venueName: PropTypes.string,
  venueAddres: PropTypes.string,
  slug: PropTypes.string,
  venueSlug: PropTypes.string,
  venuePostcode: PropTypes.string,
  venueCity: PropTypes.string,
  price: PropTypes.string,
  ticketsLink: PropTypes.string,
  sourceURL: PropTypes.string,
  sourceDescription: PropTypes.string,
  description: PropTypes.string,
  loc: PropTypes.array,
  bands: PropTypes.array,
  isFirstPage: PropTypes.bool,
  similarGigs: PropTypes.array,
  attribution: PropTypes.arrayOf(
    PropTypes.shape({ description: PropTypes.string, url: PropTypes.string })
  ),
};

export default FutureGigView;
