import React from 'react';

const ShareIconAndroid = ({ fill = 'black', className }) => (
  <svg
    className={className}
    fill={fill}
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1344 1024q133 0 226.5 93.5t93.5 226.5-93.5 226.5-226.5 93.5-226.5-93.5-93.5-226.5q0-12 2-34l-360-180q-92 86-218 86-133 0-226.5-93.5t-93.5-226.5 93.5-226.5 226.5-93.5q126 0 218 86l360-180q-2-22-2-34 0-133 93.5-226.5t226.5-93.5 226.5 93.5 93.5 226.5-93.5 226.5-226.5 93.5q-126 0-218-86l-360 180q2 22 2 34t-2 34l360 180q92-86 218-86z" />
  </svg>
);

export default ShareIconAndroid;
/*  */

/*   <svg
  viewBox="0 0 24 24"
  xmlSpace="preserve"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
  <circle cx="6" cy="12" r="4" />
  <circle cx="18" cy="6" r="4" />
  <circle cx="18" cy="18" r="4" />
  <polyline fill="none" points="18,6 6,12 18,18 " stroke="#000000" />
</svg> */
