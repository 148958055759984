import fetch from 'isomorphic-fetch';

const baseURL =
  typeof window === 'undefined'
    ? process.env.BASE_URL || `http://localhost:${process.env.PORT || 8000}`
    : '';
export const REQUEST_GIGS = 'REQUEST_GIGS';
export const RECIEVE_GIGS = 'RECIEVE_GIGS';

export function requestGigs() {
  return { type: REQUEST_GIGS };
}

export function receiveGigs(gigsData) {
  return { type: RECIEVE_GIGS, gigsData };
}

export function getGigs() {
  return (dispatch) => {
    dispatch(requestGigs());
    return fetch(`${baseURL}/api/gigs`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(receiveGigs(res.gigs));
      });
  };
}
