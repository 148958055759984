import React from 'react';
import MapMarkerIcon from '../../icons/map-marker-icon';
import styles from './location-button-styles.scss';

const LoacationButton = ({
  name,
  address,
  loc,
  className,
  cb = () => null,
}) => (
  <a
    rel="noopener"
    href={`https://maps.google.com/?q=${name}&ll=${loc[0]},${loc[1]}`}
    target="_blank"
    className={`${className} ${styles.locationLink}`}
    onClick={cb}
  >
    <MapMarkerIcon className={styles.marker} />
    <span>
      <h4 className={styles.venueName}>{name}</h4>
      <h5 className={styles.address}>{address}</h5>
    </span>
  </a>
);

export default LoacationButton;
