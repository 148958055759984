import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './logo-styles.scss'

const Logo = ({ topLine, bottomLine }) => (
  <div className={styles.logoContainer}>
    <div className={styles.topLine}>
      {topLine.map((letterData, index) => (
        <span className={styles.letter} key={index} style={letterData.style}>
          {letterData.letter}
        </span>
      ))}
    </div>
    <div className={styles.bottomLine}>
      {bottomLine.map((letterData, index) => (
        <span className={styles.letter} key={index} style={letterData.style}>
          {letterData.letter}
        </span>
      ))}
    </div>
  </div>
)

Logo.propTypes = {
  topLine: PropTypes.array,
  bottomLine: PropTypes.array,
}

function mapStateToProps (store) {
  return {
    topLine: store.randomText.logoTextData[0],
    bottomLine: store.randomText.logoTextData[1],
  }
}

export default connect(mapStateToProps)(Logo)
