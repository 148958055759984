import { Icons } from 'react-soundplayer/components';
import { connect } from 'react-redux';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { replaceTracks } from '../../redux/tracksToPlayReducer/actionCreators';

import styles from './play-button-styles.scss';
// TODO create play icon
const { PlayIconSVG } = Icons;

export default class PlayButton extends PureComponent {
  constructor() {
    super();
    this.onPlayClick = this.onPlayClick.bind(this);
  }

  onPlayClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
    if (this.props.replaceTracks) {
      this.props.replaceTracks(this.props.tracks);
    }
  }
  render() {
    return (
      <button
        aria-label={this.props.ariaLabel}
        className={`${styles.playButton} ${this.props.className}`}
        onClick={this.onPlayClick}
      >
        <PlayIconSVG className={styles.playSVG} />
      </button>
    );
  }
}

PlayButton.propTypes = {
  onClick: PropTypes.func,
  replaceTracks: PropTypes.func,
  tracks: PropTypes.array,
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PlayButton.defaultProps = {
  onClick: () => null,
  replaceTracks: () => null,
  tracks: [],
  className: '',
};

// this is wrong...... soo wrong.... needs changing soon
const mapPropsToDispatch = (dispatch) => ({
  replaceTracks: (tracks) => dispatch(replaceTracks(tracks)),
});

export const PlayButtonContainer = connect(
  null,
  mapPropsToDispatch
)(PlayButton);
