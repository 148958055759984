import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { getGigs } from './action-creators'
import GigsListWithDate from '../../components/gigs-list-with-date/gigs-list-with-date'

import StickyFloorsText from '../../components/sticky_floors_text/sticky_floors_text'
import viewStyles from '../views-styles.scss'

class GigsView extends Component {
  componentDidMount () {
    this.props.getGigs()
    if (typeof window !== 'undefined' && window.ga && this.props.isFirstPage) {
      window.ga('send', 'event', 'Start|GigsView', 'load', 'Gigs page')
    }
  }

  render () {
    return (
      <div>
        <h1>
          <StickyFloorsText
            className={viewStyles.randomLine}
            textData={this.props.gigsTextData}
          />
          <StickyFloorsText
            className={viewStyles.randomLine}
            textData={this.props.listingsTextData}
          />
        </h1>
        {this.props.gigs.map((gigsByDay) => (
          <GigsListWithDate gigsList={gigsByDay} key={gigsByDay.date} />
        ))}
      </div>
    )
  }
}

GigsView.contextTypes = {
  router: PropTypes.object,
}

GigsView.need = [() => getGigs()]

function mapStateToProps (store) {
  return {
    gigs: store.gigsData,
    gigsTextData: store.randomText.gigsListings[0],
    listingsTextData: store.randomText.gigsListings[1],
    isFirstPage: store.router.routes.length === 0,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getGigs: () => dispatch(getGigs()),
})

GigsView.propTypes = {
  gigs: PropTypes.array,
  getGigs: PropTypes.func,
  gigsTextData: PropTypes.array,
  listingsTextData: PropTypes.array,
  isFirstPage: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(GigsView)
