import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import venuesReducer from '../views/venues-view/reducer';
import gigsReducer from '../views/gigs-view/reducer';
import bandsReducer from '../views/bands-view/reducer';
import venueDetailsReducer from '../views/venue-details-view/reducer';
import gigDetailsReducer from '../views/gig-details-view/reducer';
import bandDetailsReducer from '../views/band-details-view/reducer';
import randomTextReducer from './random-text-reducer/random-text-reducer';
import tracksToPlayReducer from './tracksToPlayReducer/tracksToPlayReducer';
import uiReducer from './ui-reducer/ui-reducer';
import routerReducer from './router-reducer/router-reducer';

const configureStore = (initialState = {}) => {
  let finalCreateStore;
  const middleware = [thunk];

  if (process.env.CLIENT) {
    finalCreateStore = compose(applyMiddleware(...middleware))(createStore);
  } else {
    finalCreateStore = applyMiddleware(thunk)(createStore);
  }

  const mainReducer = combineReducers({
    venuesData: venuesReducer,
    gigsData: gigsReducer,
    bandsData: bandsReducer,
    venueDetailsData: venueDetailsReducer,
    gigDetailsData: gigDetailsReducer,
    bandDetailsData: bandDetailsReducer,
    randomText: randomTextReducer,
    tracksToPlay: tracksToPlayReducer,
    uiState: uiReducer,
    router: routerReducer,
  });

  const store = finalCreateStore(mainReducer, initialState);

  return store;
};

export default configureStore;
