import React from 'react';
import { PropTypes } from 'prop-types';

import styles from './social-media-link-styles.scss';

const SocialMediaLink = ({ children, href, className }) => (
  <a
    className={`${styles.link} ${className}`}
    rel="noopener noreferrer"
    target="blank"
    href={href}
  >
    {children}
  </a>
);

SocialMediaLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};
SocialMediaLink.defaultProps = {
  children: '',
};
export default SocialMediaLink;
