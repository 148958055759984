import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { connect } from 'react-redux';
import { getBand, getBandGigs } from './action-creators';
import { NO_IMAGE_ID } from '../../../consts/image';

import GigsList from '../../components/gigs-list/gigs-list';
import BackButton from '../../components/back-button/back-button';
import Accordion from '../../components/accordion/accordion';
import HtmlText from '../../components/html-text/html-text';
import styles from './band-details-view-styles.scss';
import HeroImage from '../../components/UIComponents/hero-image/hero-image';

import viewStyles from '../views-styles.scss';

const imageSrc = `https://res.cloudinary.com/stickyfloors/image/upload/c_lfill,g_face:auto,h_600,q_60,w_600/v1522329535/${NO_IMAGE_ID}.jpg`;
class BandDetailsView extends Component {
  static onBackClick() {
    // TODO middleware analitycs
    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'event', 'BackButton', 'click', 'Venue details page');
    }
  }
  constructor(props) {
    super(props);
    this.onBackClick = this.onBackClick.bind(this);
  }

  componentDidMount() {
    this.props.getBand(this.props.params.slug);
    this.props.getBandGigs(this.props.params.slug);
    if (typeof window !== 'undefined' && window.ga && this.props.isFirstPage) {
      window.ga(
        'send',
        'event',
        'Start|BandDetailsPage',
        'load',
        'Band details page'
      );
    }
  }

  onBackClick() {
    // TODO middleware analitycs
    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'event', 'BackButton', 'click', 'band details page');
    }
  }

  render() {
    const { bandDetails } = this.props;
    const description =
      bandDetails.description && bandDetails.description.text
        ? bandDetails.description.text.replace('. ', '.</br>')
        : '';

    return (
      <div>
        <BackButton cb={this.onBackClick} className={viewStyles.backButton} />
        <Helmet title={`${this.props.bandDetails.name}, gigs and info`}>
          <link
            rel="canonical"
            href={`https://stickyfloors.net/band/${
              this.props.bandDetails.slug
            }`}
          />
        </Helmet>
        <HeroImage className={styles.image} src={imageSrc} alt="music stage" />
        <h1 className={viewStyles.h1Name}>{this.props.bandDetails.name}</h1>
        {description &&
          description.length > 5 && (
            <section className={styles.about}>
              <Accordion
                open={false}
                collapsed={false}
                header={
                  <h3 className={viewStyles.accHeader}>About the band</h3>
                }
              >
                <HtmlText
                  htmlText={description}
                  source={
                    bandDetails.description && bandDetails.description.source
                  }
                  sourceUrl={
                    bandDetails.description && bandDetails.description.sourceUrl
                  }
                />
              </Accordion>
            </section>
          )}

        <h2 className={styles.gigs}>Gigs</h2>
        <GigsList showPlace markPastGigs gigsList={this.props.bandGigsData} />
      </div>
    );
  }
}

BandDetailsView.contextTypes = {
  router: PropTypes.object,
};

BandDetailsView.need = [
  (params) => getBand(params.slug),
  (params) => getBandGigs(params.slug),
];

function mapStateToProps(store) {
  return {
    bandDetails: store.bandDetailsData.bandDetails,
    bandGigsData: store.bandDetailsData.bandGigs,
    isFirstPage: store.router.routes.length === 0,
  };
}

BandDetailsView.propTypes = {
  bandDetails: PropTypes.object,
  bandGigsData: PropTypes.array,
  getBand: PropTypes.func,
  getBandGigs: PropTypes.func,
  params: PropTypes.object,
  isFirstPage: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  { getBand, getBandGigs }
)(BandDetailsView);
