import Helmet from 'react-helmet';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getVenue, resetVenueData } from './action-creators';
import GigsList from '../../components/gigs-list/gigs-list';
import BackButton from '../../components/back-button/back-button';

import HeroImage from '../../components/UIComponents/hero-image/hero-image';
import LocationButton from '../../components/UIComponents/location-button/location-button';
import styles from './venue-details-view-styles.scss';
import viewStyles from '../views-styles.scss';

class VenueDetailsView extends Component {
  componentDidMount() {
    if (this.props.venueDetails.slug !== this.props.params.slug) {
      this.props.resetVenueData();
      this.props.getVenue(this.props.params.slug);
    }
    if (typeof window !== 'undefined' && window.ga && this.props.isFirstPage) {
      window.ga(
        'send',
        'event',
        'Start|VenueDetailsPage',
        'load',
        'Venue details page'
      );
    }
  }

  render() {
    return (
      <div>
        {this.props.isLoading || this.props.isLoading === undefined ? (
          <h1>LOADING...</h1>
        ) : (
          <div>
            <Helmet title={`Gigs at ${this.props.venueDetails.name}`}>
              <link
                rel="canonical"
                href={`https://stickyfloors.net/venue/${
                  this.props.venueDetails.slug
                }`}
              />
            </Helmet>
            <BackButton
              cb={VenueDetailsView.onBackClick}
              className={viewStyles.backButton}
            />

            <div>
              <HeroImage
                src={`https://res.cloudinary.com/stickyfloors/image/upload/q_80/v1522329535/${
                  this.props.venueDetails.images.sq
                }.jpg`}
                alt={this.props.venueDetails.name}
              />
              <h1 className={viewStyles.h1Name}>
                {this.props.venueDetails.name}
              </h1>
              <LocationButton
                className={styles.location}
                loc={this.props.venueDetails.loc}
                address={`${this.props.venueDetails.addres} ${
                  this.props.venueDetails.postcode
                } ${this.props.venueDetails.city}`}
                name={this.props.venueDetails.name}
              />
            </div>

            <GigsList gigsList={this.props.venueDetails.venueGigs || []} />
          </div>
        )}
      </div>
    );
  }
}

VenueDetailsView.contextTypes = {
  router: PropTypes.object,
};

VenueDetailsView.need = [
  (params) => getVenue(params.slug),
  // (params) => { return getVenueGigs(params.slug); },
];

function mapStateToProps(store) {
  return {
    venueDetails: store.venueDetailsData.venueDetails,
    isLoading: store.venueDetailsData.isLoading,
    isFirstPage: store.router.routes.length === 0,
  };
}

VenueDetailsView.propTypes = {
  venueDetails: PropTypes.object,
  isLoading: PropTypes.bool,
  getVenue: PropTypes.func,
  resetVenueData: PropTypes.func,
  params: PropTypes.object,
  isFirstPage: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  { getVenue, resetVenueData }
)(VenueDetailsView);
