const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
const ALIGN_VALUES = ['top', 'middle', 'bottom'];

const pickRandomValue = (values) =>
  values[Math.floor(Math.random() * values.length)];

const LOGO_MARGINS_RIGHT_VALUES = [
  '5px',
  '2px',
  '3px',
  '4px',
  '5px',
  '8px',
  '6px',
];
const LOGO_PADDING_VALUES = ['5px', '2px', '1px', '4px', '5px', '8px', '6px'];
const LOGO_FONT_SIZES = [
  '22px',
  '24px',
  '24px',
  '26px',
  '24px',
  '22px',
  '24px',
  '32px',
];
const LOGO_ROTATE_VALUES = [
  '-10deg',
  '1deg',
  '-1deg',
  '0',
  '4deg',
  '12deg',
  '-4deg',
  '0',
  '0',
  '10deg',
  '0',
  '-11deg',
];
const LOGO_BOTTOM_VALUES = ['3px', '4px', '0', '0', '2px', '2px', '0', '-2px'];

export const getLogoTextData = (text) =>
  text.map((textLine) =>
    textLine.split('').map((letter) => ({
      letter,
      style: {
        color: '#000',
        marginRight: pickRandomValue(LOGO_MARGINS_RIGHT_VALUES),
        padding: `${pickRandomValue(LOGO_PADDING_VALUES)} ${pickRandomValue(
          LOGO_PADDING_VALUES
        )} ${pickRandomValue(LOGO_PADDING_VALUES)} ${pickRandomValue(
          LOGO_PADDING_VALUES
        )}`,
        fontSize: pickRandomValue(LOGO_FONT_SIZES),
        transform: `rotate(${pickRandomValue(LOGO_ROTATE_VALUES)})`,
        verticalAlign: pickRandomValue(ALIGN_VALUES),
        bottom: pickRandomValue(LOGO_BOTTOM_VALUES),
      },
    }))
  );

const HEADING_MARGINS_RIGHT_VALUES = [
  '5px',
  '2px',
  '3px',
  '4px',
  '5px',
  '8px',
  '6px',
];
const HEADING_PADDING_VALUES = [
  '5px',
  '2px',
  '1px',
  '4px',
  '5px',
  '8px',
  '6px',
];
const HEADING_FONT_SIZES = [30, 40];
const HEADING_ROTATE_VALUES = [
  '-10deg',
  '1deg',
  '-1deg',
  '0',
  '4deg',
  '12deg',
  '-4deg',
  '0',
  '0',
  '10deg',
  '0',
  '-11deg',
];
const HEADING_BOTTOM_VALUES = [
  '3px',
  '4px',
  '0',
  '0',
  '2px',
  '2px',
  '0',
  '-2px',
];

export const getHeadersTextData = (text) =>
  text.map((textLine) =>
    textLine.split('').map((letter) => ({
      letter,
      style: {
        backgroundColor: '#000',
        color: '#fff',
        marginRight: pickRandomValue(HEADING_MARGINS_RIGHT_VALUES),
        padding: `${pickRandomValue(HEADING_PADDING_VALUES)} ${pickRandomValue(
          HEADING_PADDING_VALUES
        )} ${pickRandomValue(HEADING_PADDING_VALUES)} ${pickRandomValue(
          HEADING_PADDING_VALUES
        )}`,
        fontSize: `${getRandomInt(HEADING_FONT_SIZES)}px`,
        transform: `rotate(${pickRandomValue(HEADING_ROTATE_VALUES)})`,
        verticalAlign: pickRandomValue(ALIGN_VALUES),
        bottom: pickRandomValue(HEADING_BOTTOM_VALUES),
        display: 'inline-block',
        position: 'relative',
      },
    }))
  );

function getRandomArbitrary(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

const getRandomGrey = () => {
  const value = getRandomArbitrary(128, 245);
  return `#${value.toString(16)}${value.toString(16)}${value.toString(16)}`;
};

const padZero = (str, len) => {
  len = len || 2;
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};

const invertColor = (hex, bw) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.', hex);
  }
  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
};

export const getRandomColors = () => {
  const bckColor = getRandomGrey();
  return {
    background: bckColor,
    color: invertColor(bckColor, true),
  };
};
