import React from 'react';

import TwitterButton from './twitter-button';
import FacebookButton from './facebook-button';
import styles from './social-media-bar-styles.scss';
import SoundcloudButton from './soundcloud-button';

const SocialMediaBar = ({
  twitterUrl,
  facebookUrl,
  soundcloudUrl,
  className,
}) => (
  <nav className={`${styles.nav} ${className}`}>
    {twitterUrl ? (
      <TwitterButton className={styles.btn} url={twitterUrl} />
    ) : null}
    {facebookUrl ? (
      <FacebookButton className={styles.btn} url={facebookUrl} />
    ) : null}
    {soundcloudUrl ? <SoundcloudButton url={soundcloudUrl} /> : null}
  </nav>
);

export default SocialMediaBar;
