import {
  REQUEST_BAND,
  RECIEVE_BAND,
  REQUEST_BAND_GIGS,
  RECIEVE_BAND_GIGS,
} from './action-creators';

const initialState = {
  bandDetails: {},
  bandGigs: [],
};

const bandDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_BAND:
      return state;
    case RECIEVE_BAND: {
      const newState = Object.assign({}, state);
      newState.bandDetails = action.bandDetails;
      return newState;
    }
    case REQUEST_BAND_GIGS:
      return state;
    case RECIEVE_BAND_GIGS: {
      const newState = Object.assign({}, state);
      newState.bandGigs = action.bandGigsDetails;
      return newState;
    }
    default:
      return state;
  }
};

export default bandDetailsReducer;
