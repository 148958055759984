export const getPriceString = (priceToParse) => {
  let priceString = '';
  if (priceToParse === '' || priceToParse === ' ' || priceToParse === null || priceToParse === undefined) {
    return priceString;
  }

  if (!isNaN(priceToParse)) {
    priceString = priceToParse > 0 ? `£${priceToParse}` : 'FREE';
  }
  const priceToParseLowerCase = String(priceToParse).toLowerCase();
  if (
    priceToParseLowerCase === 'free' ||
    priceToParseLowerCase === 'free entry'
  ) {
    priceString = 'FREE';
  }
  return priceString;
};
