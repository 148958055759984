export const ADD_TRACKS = 'ADD_TRACKS';
export const REPLACE_TRACKS = 'REPLACE_TRACKS';
export const CLEAR_TRACKS = 'CLEAR_TRACKS';
export const GOTO_NEXT_TRACK = 'GOTO_NEXT_TRACK';
export const GOTO_PREV_TRACK = 'GOTO_PREV_TRACK';


export function addTracks(tracks) {
  return { type: ADD_TRACKS, tracks };
}

export function replaceTracks(tracks) {
  return { type: REPLACE_TRACKS, tracks };
}

export function clearTracks() {
  return { type: CLEAR_TRACKS };
}

export function gotoNextTrack() {
  return { type: GOTO_NEXT_TRACK };
}

export function gotoPrevTrack() {
  return { type: GOTO_PREV_TRACK };
}
