import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import { getBands } from './action-creators';
import PlayingBandsList from '../../components/playing-bands-list/playing-bands-list';
import StickyFloorsText from '../../components/sticky_floors_text/sticky_floors_text';
import viewStyles from '../views-styles.scss';

class BandsView extends Component {
  componentDidMount() {
    this.props.getBands();
    if (typeof window !== 'undefined' && window.ga && this.props.isFirstPage) {
      window.ga('send', 'event', 'Start|BandsPage', 'load', 'Bands page');
    }
  }

  render() {
    return (
      <div>
        <Helmet title="List of bands">
          <link rel="canonical" href="https://stickyfloors.net/bands" />
        </Helmet>
        <h1>
          <StickyFloorsText
            className={viewStyles.randomLine}
            textData={this.props.bandsText}
          />
        </h1>
        <h2>Playing this month</h2>
        <PlayingBandsList />
      </div>
    );
  }
}

BandsView.contextTypes = {
  router: PropTypes.object,
};

BandsView.need = [() => getBands()];

function mapStateToProps(store) {
  return {
    bandsText: store.randomText.bands[0],
    isFirstPage: store.router.routes.length === 0,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getBands: () => dispatch(getBands()),
});

BandsView.propTypes = {
  getBands: PropTypes.func.isRequired,
  isFirstPage: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BandsView);
