import fetch from 'isomorphic-fetch';
const baseURL = typeof window === 'undefined' ? process.env.BASE_URL || (`http://localhost:${(process.env.PORT || 8000)}`) : '';
export const REQUEST_BANDS = 'REQUEST_BANDS';
export const RECIEVE_BANDS = 'RECIEVE_BANDS';

export function requestBands() {
  return { type: REQUEST_BANDS };
}

export function receiveBands(bandsData) {
  return { type: RECIEVE_BANDS, bandsData };
}

export function getBands() {
  return (dispatch) => {
    dispatch(requestBands());
    return fetch(`${baseURL}/api/bands`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
    .then((response) => response.json())
    .then(res => {
      dispatch(receiveBands(res.bands));
    });
  };
}
