import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import React from 'react';
import routes from '../shared_webapp/routes';

import configureStore from '../shared_webapp/redux/configure-store';
import { routeUpdate } from '../shared_webapp/redux/router-reducer/action-creators';

const store = configureStore(window.__INITIAL_STATE__);

const history = browserHistory;

const dest = document.getElementById('root');
const logPageView = () => {
  if (window) {
    store.dispatch(routeUpdate(window.location.pathname));
    window.scrollTo(0, 0);
    if (window.ga) {
      window.ga(
        'send',
        'pageview',
        window.location.pathname.replace(/^\s+|\s+$/g, '')
      );
    }
  }
};
render(
  <Provider store={store}>
    <Router history={history} onUpdate={logPageView} routes={routes} />
  </Provider>,
  dest
);

if (process.env.NODE_ENV !== 'production') {
  window.React = React; // enable debugger

  if (
    !dest ||
    !dest.firstChild ||
    !dest.firstChild.attributes ||
    !dest.firstChild.attributes['data-react-checksum']
  ) {
    console.error('Server-side React render was discarded. Make sure that your initial render does not contain any client-side code.'); // eslint-disable-line
  }
}
