import React from 'react';

const ExternalLinkIcon = ({ fill = 'black', className }) => (
  <svg
    className={className}
    viewBox="0 0 768 768"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill}
      d="m640,639.799622l-512,0l0,-510.093994l128,-1.906006l0,-128.000003l-256,0l0,768.000003l768,0l0,-320.000031l-128,0l0,192.000031zm-256,-640.000003l128,128.000003l-192,191.999969l128,128l192,-191.999985l128,127.999985l0,-383.999972l-384,0z"
    />
  </svg>
);

export default ExternalLinkIcon;
