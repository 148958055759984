import React from 'react';

const ShareIconIOS = ({ fill = 'black', className }) => (
  <svg
    className={className}
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <polygon points="7,1.914 7,11.707 8,11.707 8,1.914 10.146,4.061 10.854,3.354 7.5,0 4.146,3.354 4.854,4.061 " />
    <polygon points="9.5,6.207 9.5,7.207 14,7.207 14,14.207 1,14.207 1,7.207 5.5,7.207 5.5,6.207 0,6.207 0,15.207 15,15.207   15,6.207 " />
  </svg>
);

export default ShareIconIOS;
