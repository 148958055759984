import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import viewStyles from '../views-styles.scss';
import BackButton from '../../components/back-button/back-button';
import SocialMediaBar from '../../components/social-media-bar/social-media-bar';

class AboutView extends Component {
  static onBackClick() {
    // TODO middleware analitycs
    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'event', 'BackButton', 'click', 'About page');
    }
  }
  renderTwitterIcon() {
    return (
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width="112px"
        height="112px"
        viewBox="0 0 430.117 430.117"
      >
        <g>
          <path
            d="M381.384,198.639c24.157-1.993,40.543-12.975,46.849-27.876
        c-8.714,5.353-35.764,11.189-50.703,5.631c-0.732-3.51-1.55-6.844-2.353-9.854c-11.383-41.798-50.357-75.472-91.194-71.404
        c3.304-1.334,6.655-2.576,9.996-3.691c4.495-1.61,30.868-5.901,26.715-15.21c-3.5-8.188-35.722,6.188-41.789,8.067
        c8.009-3.012,21.254-8.193,22.673-17.396c-12.27,1.683-24.315,7.484-33.622,15.919c3.36-3.617,5.909-8.025,6.45-12.769
        C241.68,90.963,222.563,133.113,207.092,174c-12.148-11.773-22.915-21.044-32.574-26.192
        c-27.097-14.531-59.496-29.692-110.355-48.572c-1.561,16.827,8.322,39.201,36.8,54.08c-6.17-0.826-17.453,1.017-26.477,3.178
        c3.675,19.277,15.677,35.159,48.169,42.839c-14.849,0.98-22.523,4.359-29.478,11.642c6.763,13.407,23.266,29.186,52.953,25.947
        c-33.006,14.226-13.458,40.571,13.399,36.642C113.713,320.887,41.479,317.409,0,277.828
        c108.299,147.572,343.716,87.274,378.799-54.866c26.285,0.224,41.737-9.105,51.318-19.39
        C414.973,206.142,393.023,203.486,381.384,198.639z"
            fill="black"
          />
        </g>
      </svg>
    );
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && window.ga && this.props.isFirstPage) {
      window.ga('send', 'event', 'Start|AboutPage', 'load', 'About page');
    }
  }

  render() {
    return (
      <section className={viewStyles.section}>
        <Helmet title="About">
          <link rel="canonical" href="https://stickyfloors.net/about" />
        </Helmet>
        <BackButton
          className={viewStyles.backButton}
          cb={AboutView.onBackClick}
        />
        <h1>About</h1>
        <SocialMediaBar
          twitterUrl="https://twitter.com/_stickyfloors_"
          facebookUrl="https://www.facebook.com/StickyFloors-1170717093093696"
          soundcloudUrl="https://soundcloud.com/stickyfloors"
        />
        <h3>hello!</h3>
        <p>This is super early version small London gigs listing </p>
        <p>
          All pages are autocratically generated and may contain mistakes and
          errors
        </p>
        <p>
          before going to any of our listed gigs please check venue website!
        </p>
        <p>
          If you would like your venue to be listed or have any other questions
          please drop us an email
        </p>
        <a href="mailto:stickyfloorsgigs@gmail.com">
          stickyfloorsgigs@gmail.com
        </a>
        <h2>ah btw we are on TWITTER noW!</h2>
        <a
          className="twitterButton"
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/_stickyfloors_"
        >
          {this.renderTwitterIcon()}
        </a>
      </section>
    );
  }
}

AboutView.contextTypes = {
  router: PropTypes.object,
};
AboutView.propTypes = {
  isFirstPage: PropTypes.bool.isRequired,
};

function mapStateToProps(store) {
  return { isFirstPage: store.router.routes.length === 0 };
}

export default connect(mapStateToProps)(AboutView);
