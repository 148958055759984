import React from 'react';
import PropTypes from 'prop-types';

import { SoundPlayerContainer } from 'react-soundplayer/addons';
import { PlayButton, Timer } from 'react-soundplayer/components';

import CloseButton from './close-button';
import TrackInfo from './track-info';
import SoundCloudLogoIcon from '../icons/SoundCloudLogoIcon';
import ProgressWrapper from './progress-wrapper';
import styles from './player-styles.scss';

const Player = (props) => {
  if (!props.streamUrl) return null;
  return (
    <div className={styles.container}>
      <SoundPlayerContainer
        autoPlay
        clientId="ZuIGe22QiQc46uNRXhMqlPoethepgoVZ"
        streamUrl={props.streamUrl}
        onStopTrack={props.onTrackFinish}
      >
        <ProgressWrapper />
        <PlayButton className={styles.playButton} />
        <TrackInfo
          permalink={props.permalink}
          trackLink={props.trackLink}
          title={props.title}
          username={props.username}
        />
        <Timer className={styles.timer} />
        <SoundCloudLogoIcon className={styles.scLogo} />
        <CloseButton onCloseClick={props.onCloseClick} />
      </SoundPlayerContainer>
    </div>
  );
};

Player.propTypes = {
  onCloseClick: PropTypes.func,
  onTrackFinish: PropTypes.func,
  streamUrl: PropTypes.string,
  title: PropTypes.string,
  trackLink: PropTypes.string,
  username: PropTypes.string,
  permalink: PropTypes.string,
};

Player.defaultProps = {};

export default Player;
