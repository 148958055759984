import {
  ADD_TRACKS,
  REPLACE_TRACKS,
  CLEAR_TRACKS,
  GOTO_NEXT_TRACK,
  GOTO_PREV_TRACK,
} from './actionCreators';

const initialState = {
  tracks: [],
  activeIndex: 0,
  loop: true,
};

const tracksToPlayReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TRACKS :
      return { ...state, tracks: [...state.tracks, ...action.tracks] };
    case REPLACE_TRACKS:
      return { ...state, tracks: [...action.tracks] };
    case CLEAR_TRACKS:
      return Object.assign({}, initialState);
    case GOTO_NEXT_TRACK:
      const activeNextIndex = state.activeIndex + 1 < state.tracks.length
        ? state.activeIndex + 1 : 0;
      return { ...state, activeIndex: activeNextIndex };
    case GOTO_PREV_TRACK:
      const activePrevIndex = state.activeIndex === 0
        ? state.tracks.length - 1 : state.activeIndex - 1;
      return { ...state, activeIndex: activePrevIndex };
    default:
      return state;
  }
};

export default tracksToPlayReducer;
