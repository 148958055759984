import { getLogoTextData, getHeadersTextData } from './text-utils';

import { ROUTE_UPDATE } from '../router-reducer/action-creators';

const initialState = {
  logoTextData: getLogoTextData(['Sticky', 'Floors']),
  gigsListings: getHeadersTextData(['Gigs', 'Listings']),
  venues: getHeadersTextData(['Venues']),
  bands: getHeadersTextData(['Bands']),
};

const randomTextReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROUTE_UPDATE:
      return {
        ...state,
        logoTextData: getLogoTextData(['Sticky', 'Floors']),
      };
    default:
      return state;
  }
};

export default randomTextReducer;
