import { ROUTE_UPDATE } from './action-creators';

const initialState = {
  routes: [],
};

const routerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROUTE_UPDATE:
      return {
        routes: [...state.routes, action.pathname],
      };

    default:
      return state;
  }
};

export default routerReducer;
