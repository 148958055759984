import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import VenueListItem from './venue-list-item';

const VenuesView = ({ venues }) => (
  <ul>
    {venues.map((venue) => <VenueListItem key={venue._id} venue={venue} />)}
  </ul>
);

function mapStateToProps(store) {
  return {
    venues: store.venuesData,
  };
}

VenuesView.propTypes = {
  venues: PropTypes.array,
};

VenuesView.defaultProps = {
  venues: [],
};

export default connect(mapStateToProps)(VenuesView);
