import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import PropTypes from 'prop-types';
import { Link } from 'react-router';
import PlayButton from '../play-button/play-button';
import { replaceTracks } from '../../redux/tracksToPlayReducer/actionCreators';

import styles from './playing-bands-list-styles.scss';

const renderGenre = (genre) => {
  if (genre && genre.length > 0) {
    return <h4 className={styles.genre}>{genre.join(' // ')}</h4>;
  }
  return null;
};

const ListItem = ({ index, slug, name, genre, onPlay, date, venue }) => (
  <li className={styles.item}>
    {onPlay ? (
      <PlayButton ariaLabel="button" onClick={onPlay} size={42} />
    ) : null}
    <Link
      className={styles.link}
      key={index}
      href={`/band/${slug}`}
      to={`/band/${slug}`}
    >
      <h2 className={styles.name}>{name}</h2>
      {renderGenre(genre)}

      <h3 className={styles.date}>
        <time dateTime={date}>{moment(date).format('Do MMM')}</time>
      </h3>
      <h4 className={styles.venue}>{venue.name}</h4>
    </Link>
  </li>
);

const PlayingBandsList = ({ bands, onPlayClick }) => (
  <ul>
    {bands.map(({ band, gig }, index) => (
      <ListItem
        date={gig.date}
        venue={gig.venue}
        key={index}
        slug={band.slug}
        name={band.name}
        numberOfGigs={band.numberOfGigs}
        genre={band.genre}
        onPlay={band.tracks.length ? () => onPlayClick(band.tracks) : undefined}
      />
    ))}
  </ul>
);

PlayingBandsList.contextTypes = {
  router: PropTypes.object,
};

function mapStateToProps(store) {
  return {
    bands: store.bandsData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  onPlayClick: (tracks) => {
    // TODO middleware analitycs
    if (typeof window !== 'undefined' && window.ga) {
      window.ga(
        'send',
        'event',
        'PlayButton',
        'click',
        'bands listing play button'
      );
    }
    dispatch(replaceTracks(tracks));
  },
});

PlayingBandsList.propTypes = {
  bands: PropTypes.array,
  onPlayClick: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayingBandsList);
