import React from 'react';
import SocialMediaLink from './social-media-link/social-media-link';
import FacebookIcon from '../icons/facebook-icon';

const FacebookButton = ({ url, className }) => (
  <SocialMediaLink className={className} href={url}>
    <FacebookIcon />
  </SocialMediaLink>
);
export default FacebookButton;
