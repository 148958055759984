import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './share-button-styles.scss';
import ShareIconIOS from '../../icons/share-icon-ios';
import ShareIconAndroid from '../../icons/share-icon-android';

class ShareButton extends Component {
  constructor(props) {
    super(props);

    this.state = { isSharingAvailable: false, isAndroid: false };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      isSharingAvailable: !!navigator.share,
      isAndroid: navigator.userAgent.indexOf('Android') >= 0,
    });
  }

  componentWillUnmount() {
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { title, text, url } = this.props;
    navigator
      .share({
        title,
        text,
        url,
      })
      .then(() => {
        if (typeof window !== 'undefined' && window.ga) {
          window.ga(
            'send',
            'event',
            'WebShareDone',
            'click',
            'Gig details page'
          );
        }
      })
      .catch(() => {
        if (typeof window !== 'undefined' && window.ga) {
          window.ga(
            'send',
            'event',
            'WebShareFail',
            'click',
            'Gig details page'
          );
        }
      });
  }

  render() {
    const showClassName = this.state.isSharingAvailable
      ? ''
      : ` ${styles.dontShow}`;

    const className = `${styles.shareButton}${showClassName} ${
      this.props.className
    }`;
    return (
      <button className={className} onClick={this.handleClick}>
        {this.state.isAndroid ? (
          <ShareIconAndroid className={styles.icon} />
        ) : (
          <ShareIconIOS className={styles.icon} />
        )}
      </button>
    );
  }
}

ShareButton.defaultProps = {
  className: '',
  text: '',
};

ShareButton.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ShareButton;
