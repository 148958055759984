import React from 'react';
import PropTypes from 'prop-types';
import styles from './html-text-styles.scss';

const getCredit = (attribution) =>
  attribution.length > 0 ? (
    <span className="description-credit">
      Source:{' '}
      {attribution.map((attr) => (
        <a key={`${attr.description}`} target="_blank" href={attr.url}>
          {attr.description}
        </a>
      ))}
    </span>
  ) : null;

const HtmlText = ({ htmlText, attribution, className }) => {
  if (!htmlText) return null;
  return (
    <div className={`${styles.htmlContent} ${className}`}>
      <p dangerouslySetInnerHTML={{ __html: htmlText }} />
      {getCredit(attribution)}
    </div>
  );
};

HtmlText.defaultProps = {
  htmlText: '',
  className: '',
  attribution: [],
};

HtmlText.propTypes = {
  htmlText: PropTypes.string,
  className: PropTypes.string,
  attribution: PropTypes.arrayOf(
    PropTypes.shape({ description: PropTypes.string, url: PropTypes.string })
  ),
};

export default HtmlText;
