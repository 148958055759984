import { connect } from 'react-redux';

import Player from './player';

import {
  clearTracks,
  gotoNextTrack,
} from '../../redux/tracksToPlayReducer/actionCreators';

const mapDispatchToProps = (dispatch) => ({
  onCloseClick: () => dispatch(clearTracks()),
  onTrackFinish: () => dispatch(gotoNextTrack()),
});

const mapStateToProps = (state) => {
  const { tracks, activeIndex } = state.tracksToPlay;
  if (tracks[activeIndex]) {
    return { ...tracks[activeIndex] };
  }
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Player);

/*
artwork="https://i1.sndcdn.com/artworks-000213506374-beczn5-large.jpg"
title="Restraint Of Beasts Restraint Of Beasts Restraint Of Beasts ss sdss ds ds d s ds d sd sd s ds ds d s ds d"
userName="Indus Traps Indus Traps Indus Traps Indus Traps Indus Trads csd ssd ssdsddssd sdsdds sdsdps Indus Traps"
userPermalinkUrl="https://soundcloud.com/theindustraps/"
streamUrl="https://soundcloud.com/theindustraps/restraint-of-beasts"


*/
