import fetch from 'isomorphic-fetch';

const baseURL =
  typeof window === 'undefined'
    ? process.env.BASE_URL || `http://localhost:${process.env.PORT || 8000}`
    : '';
export const REQUEST_BAND = 'REQUEST_BAND';
export const RECIEVE_BAND = 'RECIEVE_BAND';

export const REQUEST_BAND_GIGS = 'REQUEST_BAND_GIGS';
export const RECIEVE_BAND_GIGS = 'RECIEVE_BAND_GIGS';

export function requestBandGigs() {
  return { type: REQUEST_BAND_GIGS };
}

export function receiveBandGigs(bandGigsDetails) {
  return { type: RECIEVE_BAND_GIGS, bandGigsDetails };
}

export function getBandGigs(slug) {
  return (dispatch) => {
    dispatch(requestBandGigs());
    return fetch(`${baseURL}/api/bands/${slug}/gigs`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(receiveBandGigs(res.gigs));
      });
  };
}

export function requestBand() {
  return { type: REQUEST_BAND };
}

export function receiveBand(bandDetails) {
  return { type: RECIEVE_BAND, bandDetails };
}

export function getBand(slug) {
  return (dispatch) => {
    dispatch(requestBand());
    return fetch(`${baseURL}/api/bands/${slug}`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(receiveBand(res.band));
      });
  };
}
