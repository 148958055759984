import { Route, IndexRoute } from 'react-router';
import React from 'react';
import App from './app';
import VenuesView from './views/venues-view/venues-view';
import GigsView from './views/gigs-view/gigs-view';
import AboutView from './views/about-view/about-view';
import BandsView from './views/bands-view/bands-view';
import VenueDetailsView from './views/venue-details-view/venue-details-view';
import GigDetailsView from './views/gig-details-view/gig-details-view';
import BandDetailsView from './views/band-details-view/band-details-view';

const routes = (
  <Route path="/" component={App}>
    <IndexRoute component={GigsView} />
    <Route path="/gigs" component={GigsView} />
    <Route path="/bands" component={BandsView} />
    <Route path="/about" component={AboutView} />
    <Route path="/venues" component={VenuesView} />
    <Route path="/venue/:slug" component={VenueDetailsView} />
    <Route path="/gig/:slug" component={GigDetailsView} />
    <Route path="/band/:slug" component={BandDetailsView} />
  </Route>
);

export default routes;
