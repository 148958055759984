import { REQUEST_BANDS, RECIEVE_BANDS } from './action-creators';

const initialState = [];

const bandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_BANDS:
      return state;
    case RECIEVE_BANDS:
      return action.bandsData;
    default:
      return state;
  }
};

export default bandsReducer;
