import Helmet from 'react-helmet';
import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getGig, resetGig, getSimilarGigs } from './action_creators';

import FutureGigView from './future-gig-view/future-gig-view';

const htmlToText = require('html-to-text');

class GigDetailsView extends Component {
  static getTwitterImage(venueImageSq, img) {
    if (img && img.cloudinaryId) {
      return `https://res.cloudinary.com/stickyfloors/image/upload/c_fill,e_grayscale,g_faces,h_628,q_auto:best,w_1268/v1541513319/${
        img.cloudinaryId
      }.jpg`;
    }

    return `https://res.cloudinary.com/stickyfloors/image/upload/q_80/v1522329535/${venueImageSq}.jpg`;
  }
  static getStructuredData(gigDetails) {
    const performer =
      gigDetails.bands.length > 0
        ? gigDetails.bands.map((band) => ({
            '@type': 'PerformingGroup',
            name: band.name,
            sameAs: `https://stickyfloors.net/band/${band.slug}`,
          }))
        : undefined;
    return {
      '@context': 'http://schema.org',
      '@type': ['Event', 'MusicEvent'],
      name: gigDetails.title,
      startDate: gigDetails.date,
      endDate: moment(gigDetails.date).format('YYYY-MM-DD'),
      location: {
        '@type': 'Place',
        name: gigDetails.venueData.name,
        address: {
          '@type': 'PostalAddress',
          streetAddress: gigDetails.venueData.addres,
          addressLocality: gigDetails.venueData.city,
          postalCode: gigDetails.venueData.postcode,
          addressRegion: gigDetails.venueData.city,
          addressCountry: 'UK',
        },
      },
      performer,
      image: [
        gigDetails.img
          ? gigDetails.img.url
          : GigDetailsView.getTwitterImage(
              gigDetails.venueData.images.sq,
              gigDetails.img
            ),
      ],
      description: htmlToText
        .fromString(gigDetails.description, { ignoreHref: true })
        .substring(0, 200),
    };
  }
  componentWillMount() {
    if (this.props.gigDetails.slug !== this.props.params.slug) {
      this.props.resetGig();
      this.props.getGig(this.props.params.slug);
      this.props.getSimilarGigs(this.props.params.slug);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.params.slug !== nextProps.params.slug) {
      this.props.resetGig();
      this.props.getGig(nextProps.params.slug);
      this.props.getSimilarGigs(nextProps.params.slug);
    }
  }

  getTitle() {
    return `${this.props.gigDetails.title} at ${
      this.props.gigDetails.venueData.name
    }`;
  }

  renderGig() {
    const venueImg = `https://res.cloudinary.com/stickyfloors/image/upload/q_80/v1522329535/${
      this.props.gigDetails.venueData.images.sq
    }.jpg`;

    return (
      <FutureGigView
        venueImg={venueImg}
        title={this.props.gigDetails.title}
        date={this.props.gigDetails.date}
        img={this.props.gigDetails.img || {}}
        venueName={this.props.gigDetails.venueData.name}
        venueSlug={this.props.gigDetails.venueData.slug}
        venueAddres={this.props.gigDetails.venueData.addres}
        venuePostcode={this.props.gigDetails.venueData.postcode}
        loc={this.props.gigDetails.venueData.loc}
        venueCity={this.props.gigDetails.venueData.city}
        price={this.props.gigDetails.price}
        ticketsLink={this.props.gigDetails.ticketsLink}
        description={this.props.gigDetails.description}
        bands={this.props.gigDetails.bands}
        attribution={this.props.gigDetails.attribution}
        slug={this.props.gigDetails.slug}
        similarGigs={this.props.similarGigs}
        isSimilarGigsLoading={this.props.isSimilarGigsLoading}
        isFirstPage={this.props.isFirstPage}
      />
    );
  }

  renderLaodingState() {
    return <h1>LOADING...</h1>;
  }

  render() {
    if (this.props.isLoading) {
      return this.renderLaodingState();
    }

    return (
      <div>
        <Helmet title={this.getTitle()}>
          <link
            rel="canonical"
            href={`https://stickyfloors.net/gig/${this.props.gigDetails.slug}`}
          />
          <script type="application/ld+json">
            {JSON.stringify(
              GigDetailsView.getStructuredData(this.props.gigDetails)
            )}
          </script>
          <meta property="og:title" content={this.props.gigDetails.title} />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://stickyfloors.net/gig/${
              this.props.gigDetails.slug
            }`}
          />
          <meta name="theme-color" content="#000000" />
          <meta charSet="utf-8" />
          <meta
            property="og:image"
            content={GigDetailsView.getTwitterImage(
              this.props.gigDetails.venueData.images.sq,
              this.props.gigDetails.img
            )}
          />
          <meta property="og:site_name" content="Sticky Floors" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:description" content={this.getTitle()} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="_stickyfloors_" />
          <meta name="twitter:title" content={this.props.gigDetails.title} />
          <meta name="twitter:description" content={this.getTitle()} />
          <meta name="twitter:image:alt" content="Sticky Floors Gig Graphics" />
          <meta
            name="twitter:image"
            content={GigDetailsView.getTwitterImage(
              this.props.gigDetails.venueData.images.sq,
              this.props.gigDetails.img
            )}
          />
        </Helmet>
        {this.renderGig()}
      </div>
    );
  }
}

GigDetailsView.contextTypes = {
  router: PropTypes.object,
};

GigDetailsView.need = [
  (params) => getGig(params.slug),
  (params) => getSimilarGigs(params.slug),
];

function mapStateToProps(store) {
  return {
    gigDetails: store.gigDetailsData.gigDetails,
    similarGigs: store.gigDetailsData.similarGigs || [],
    isSimilarGigsLoading: store.gigDetailsData.isSimilarGigsLoading,
    isLoading: store.gigDetailsData.isLoading,
    isFirstPage: store.router.routes.length === 0,
  };
}

GigDetailsView.propTypes = {
  gigDetails: PropTypes.object,
  similarGigs: PropTypes.array,
  isSimilarGigsLoading: PropTypes.bool,
  getGig: PropTypes.func,
  getSimilarGigs: PropTypes.func,
  isLoading: PropTypes.bool,
  resetGig: PropTypes.func,
  params: PropTypes.object,
  isFirstPage: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  { getGig, resetGig, getSimilarGigs }
)(GigDetailsView);
