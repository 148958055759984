import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'react-soundplayer/components';
import styles from './player-styles.scss';

class ProgressWrapper extends PureComponent {
  render() {
    return (
      <Progress
        className={styles.progress}
        duration={this.props.duration}
        currentTime={this.props.currentTime}
        innerClassName={`${styles.progressInner} ${
          this.props.playing ? `${styles.progressFlashing}` : ''
        }`}
      />
    );
  }
}

ProgressWrapper.propTypes = {
  playing: PropTypes.bool,
  duration: PropTypes.number,
  currentTime: PropTypes.number,
};

export default ProgressWrapper;
