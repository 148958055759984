import fetch from 'isomorphic-fetch';

const baseURL =
  typeof window === 'undefined'
    ? process.env.BASE_URL || `http://localhost:${process.env.PORT || 8000}`
    : '';
export const REQUEST_VENUES = 'REQUEST_VENUES';
export const RECIEVE_VENUES = 'RECIEVE_VENUES';

export function requestVenues() {
  return { type: REQUEST_VENUES };
}

export function receiveVenues(venuesData) {
  return { type: RECIEVE_VENUES, venuesData };
}

export function getVenues() {
  return (dispatch) => {
    dispatch(requestVenues());
    return fetch(`${baseURL}/api/venues`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(receiveVenues(res.venues));
      });
  };
}
