import { REQUEST_VENUES, RECIEVE_VENUES } from './action-creators';

const initialState = [];

const venuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_VENUES:
      return state;
    case RECIEVE_VENUES:
      return action.venuesData;
    default:
      return state;
  }
};

export default venuesReducer;
