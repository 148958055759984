import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

import styles from './hero-image-styles.scss';

class HeroImage extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, isLoaded: false, isError: false };
    this.onDomLoaded = this.onDomLoaded.bind(this);
    this.onError = this.onError.bind(this);
    this.onLoad = this.onLoad.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isLoading: true, isLoaded: false, isError: false });
    if (document.readyState === 'complete') {
      this.onDomLoaded();
    } else {
      window.addEventListener('load', this.onDomLoaded);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.src && nextProps.src !== this.props.src) {
      this.setState({ isLoading: true, isLoaded: false, isError: false });
      this.cleanUp();
      this.loadImg();
    }
  }

  componentWillUnmount() {
    this.cleanUp();
  }

  onDomLoaded() {
    this.loadImg();
  }

  onLoad() {
    this.setState({ isLoading: false, isLoaded: true, isError: false });
  }

  onError() {
    this.setState({ isLoading: false, isLoaded: false, isError: true });
  }

  getImage() {
    return (
      <img
        className={`${this.props.imageClassName} ${styles.imageAnimation}`}
        alt={this.props.alt}
        width="100%"
        src={this.props.src}
      />
    );
  }

  getContent() {
    if (this.state.isLoaded) {
      return this.getImage();
    }
    if (this.state.isLoading) {
      return <div className={styles.loader} />;
    }

    if (this.state.isError) {
      return null;
    }
    return null;
  }

  cleanUp() {
    window.removeEventListener('onload', this.onDomLoaded);
    if (this.i) this.unloadImg();
  }

  loadImg() {
    // eslint-disable-next-line no-undef
    this.i = new Image();
    this.i.src = this.props.src;
    // TODO CHECK WHY IT IS NOT WORKING IN FIREFOX
    // await this.i.decode();
    this.i.addEventListener('load', this.onLoad);
    this.i.addEventListener('error', this.onError);

    this.i.onerror = this.onError;
  }

  unloadImg() {
    this.i.removeEventListener('load', this.onLoad);
    this.i.removeEventListener('error', this.onError);

    delete this.i.onerror;
    delete this.i.onload;
    try {
      delete this.i.src;
    } catch (e) {
      // On Safari in Strict mode this will throw an exception,
      //  - https://github.com/mbrevda/react-image/issues/187
      // We don't need to do anything about it.
    }
    delete this.i;
  }

  render() {
    const content = this.getContent();
    const img = this.getImage();
    return (
      <div className={this.props.className || styles.wrapper}>
        {content}
        <noscript
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: ReactDOMServer.renderToStaticMarkup(img),
          }}
        />
      </div>
    );
  }
}

HeroImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
};

HeroImage.defaultProps = {
  alt: '',
  className: '',
  imageClassName: '',
};

export default HeroImage;
