import React from 'react';
import PropTypes from 'prop-types';

import styles from './accordion-styles.scss';

const Accordion = ({ open, header, children }) => (
  <details open={open}>
    <summary className={styles.summary}>{header}</summary>
    <div className="content">{children}</div>
  </details>
);

Accordion.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  open: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Accordion.defaultProps = {
  open: true,
  children: '',
};

export default Accordion;
