import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import styles from './too-late-notice-styles.scss';

const TooLateNotice = ({ date }) => (
  <h2 className={styles.notice}>
    Sorry, You are too late. This gig happened{' '}
    <span className={styles.time}> {moment(date).from(moment())}</span>
  </h2>
);

TooLateNotice.propTypes = {
  date: PropTypes.string.isRequired,
};
export default TooLateNotice;
