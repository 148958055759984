import React from 'react';
import PropTypes from 'prop-types';

class StickyFloorsText extends React.Component {
  shouldComponentUpdate() {
    return true;
  }

  renderLetter(letter, style, index) {
    return (
      <span key={index} style={style}>
        {letter}
      </span>
    );
  }
  render() {
    return (
      <span className={this.props.className}>
        {this.props.textData.map((letterData, index) =>
          this.renderLetter(letterData.letter, letterData.style, index)
        )}
      </span>
    );
  }
}

StickyFloorsText.propTypes = {
  className: PropTypes.string,
  textData: PropTypes.array,
  fontSize: PropTypes.array,
};

StickyFloorsText.defaultProps = {
  className: '',
};

export default StickyFloorsText;
