import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './player-styles.scss';

class CloseButton extends PureComponent {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.onCloseClick) {
      this.props.soundCloudAudio.stop();
      this.props.soundCloudAudio.unbindAll();

      this.props.onCloseClick();
    }
  }

  render() {
    return (
      <button onClick={this.handleClick} className={styles.closeButton}>
        X
      </button>
    );
  }
}

CloseButton.propTypes = {
  onCloseClick: PropTypes.func,
};

export default CloseButton;
