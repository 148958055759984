import React from 'react';
import SocialMediaLink from './social-media-link/social-media-link';
import SoundcloudIcon from '../icons/soundcloud-icon';

const SoundcloudButton = ({ url, className }) => (
  <SocialMediaLink className={className} href={url}>
    <SoundcloudIcon />
  </SocialMediaLink>
);

export default SoundcloudButton;
